/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isString } from 'lodash';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import { openSnackbar } from '@actions/snackbar-actions';
import {
  clearItemSelection,
  closeAreaSelection,
  setLinkedElement,
  setLinkedElementActive
} from '@actions/map-actions';
import ThemeStyle, { SECONDARY_TEXT } from '@components/theme-style';
import DetailHeader from '@components/map/info-tray/info-detail/detail-header';
import DetailText from '@components/map/info-tray/info-detail/detail-text';
import { key as apiKey } from '@constants/google-maps';
import { getDirectionsUrl } from '@constants/endpoints';
import LargeMedia from '@shared/large-media';
import SmallMedia from '@shared/small-media';
import StaticStreetView from '@shared/static-street-view';
import {
  getAliases,
  getCategory,
  getTypes,
  resolveFields
} from '@utils/map-data-utils';
import { getBboxCenter } from '@utils/geometry-utils';
// Need to disable warning about unused classes in the imported CSS
// file, because they are used by the template, and are not defined
// in this code:
/* eslint-disable css-modules/no-unused-class */
import styles from './template-utils.scss';

// Main entry point to render a template:
export const renderTemplate = (template, options) => {
  // Handle text nodes:
  if (isString(template)) {
    return template;
  }
  const html = [];
  // Handle children array nodes:
  if (Array.isArray(template)) {
    template.forEach(item => {
      Object.keys(item).forEach(key => {
        const value = item[key];
        html.push(render(key, value, options));
      });
    });
  } else {
    // Check if it contains a condition which must be true to render.
    if (Object.keys(template).includes('if')) {
      for (const { field, level, condition } of template.if) {
        const value = level === 'root' ? options.data[field] : options.data.attrs[field];
        // eslint-disable-next-line max-depth
        if (condition === 'is-not-null' && (value === null || typeof value === 'undefined')) {
          return [<div />];  // eslint-disable-line react/jsx-key
        }
      }
    }
    // Handle the standard object node:
    Object.keys(template).forEach(key => {
      const value = template[key];
      html.push(render(key, value, options));
    });
  }
  return html;
};

const getStyle = (template, options) => {
  let tempStyles = {};
  if (template.class) {
    const classes = template.class.split(',').map(clazz => clazz.trim());
    classes.forEach(clazz => {
      tempStyles = {
        ...options.classNames[clazz],
        ...tempStyles
      };
    });
  }
  if (template.style) {
    tempStyles = { ...tempStyles, ...template.style };
  }
  return tempStyles;
};

const LocationsLink = options => {
  const dispatch = useDispatch();
  const { data: { remote_id }, props: { allLocations, detailData } } = options;
  // Only display the locations link for geo-temporal entities:
  if (typeof remote_id === 'undefined') {
    return null;
  }
  const { layerData } = detailData;

  const viewLocations = () => {
    dispatch(closeAreaSelection());
    dispatch(setLinkedElement(layerData, allLocations));
    dispatch(setLinkedElementActive());
  };

  const { data: { attrs: { start_date, end_date } } } = options;

  if (allLocations.length > 1) {
    return (
      <div className={styles.locationsLinkContainer}>
        {start_date && end_date && <div className={styles.bullet}>&bull;</div>}
        <div className={styles.link} onClick={viewLocations}>
          View all {allLocations.length} locations
        </div>
      </div>
    );
  }
  return null;
};

const BackButton = options => {
  const { props, streetView, value } = options;
  const dispatch = useDispatch();
  const onClick = () => dispatch(clearItemSelection());

  if (props.areaCount > 1) {
    return (
      <IconButton
        className={classNames(styles.topButton, {[styles.condensed]: !streetView})}
        style={getStyle(value, options)}
        onClick={onClick}
      >
        <Icon>arrow_back</Icon>
      </IconButton>
    );
  }
  return null;
};

class ShareButton extends Component {
  render() {
    const { data: { remote_id }, props, streetView, value } = this.props;
    // Only display the share button for geo-temporal entities:
    if (typeof remote_id === 'undefined') {
      return null;
    }
    const showMessage = message => this.props.openSnackbar(message);
    return (
      <Fragment>
        <IconButton
          className={classNames(styles.topButton, {[styles.condensed]: !streetView})}
          style={getStyle(value, this.props)}
          onClick={() => {
            const { detailData: { layerData: { name } } } = props;
            const url = `${location.origin}/map?link_type=${name}&link_value=${remote_id}`;
            // Copy the URL to the clipboard:
            navigator.clipboard.writeText(url);
            showMessage('Location link copied into clipboard.');
          }}
        >
          <Icon>share</Icon>
        </IconButton>
      </Fragment>
    );
  }
}

const ConnectedShareButton = connect(null, { openSnackbar })(ShareButton);

// Save a reference of a HTML DOM node in the global window object.
const saveNode = (name, id) => {
  window[name] = document.getElementById(id);
};

// Save all the references we need to rebuild the tray/map layout before printing:
const saveNodes = () => {
  saveNode('infoTrayWrapper', 'info-tray-wrapper');  // Reference to the tray wrapper.
  saveNode('infoTrayContent', 'info-tray-content');  // Reference to the content of the tray.
  saveNode('mapContainer', 'map-container');  // Reference to map container.
  saveNode('filterTray', 'filter-tray');  // Reference to the filter tray.
};

// Resize the map to use the space left by the details tray:
const resizeMap = () => {
  const { mapContainer: map } = window;
  map.style.position = 'absolute';
  map.style.right = 0;
  map.style.height = '100%';
  map.style.width = '34%'; // It's 1/3rd of the screen, add a bit more (i.e 34%) to avoid an empty gap.
};

// Restore the info tray to its original size:
const restoreInfoTraySize = () => {
  window.infoTrayWrapper.style.width = window.oldWrapperWidth;
  window.infoTrayContent.style.width = window.oldContentWidth;

  // Restore tray display:
  const { filterTray } = window;
  filterTray.style.display = 'flex';

  // Restore body width:
  document.body.style.width = '100%';
  document.body.style.height = '100%';
};

// Restore the map to use the full width:
const restoreMap = () => {
  const { mapContainer: map } = window;
  map.style.position = 'relative';
  map.style.right = 'auto';
  map.style.height = 'auto';
  map.style.width = 'auto';
};

// Checks if the new tray width is the same as the saved one.
export const restoreFinished = () => {
  const { infoTrayWrapper: wrapper } = window;
  const newWidth = wrapper.offsetWidth;
  const oldWidth = parseInt(window.oldWrapperWidth.replace('px', ''), 10);
  return oldWidth === newWidth;
};

// Center the map to the location, after we restore the map after printing.
export const centerMap = () => {
  if (window.centerMapRetry > 0) {
    // The map/tray size restore doesn't immediatelly restores the sizes, it takes
    // some milliseconds (200 aprox, but it depends on the machine/browser).
    // Thus we must wait until it's restored to the actual map centering.
    window.centerMapRetry--;  // eslint-disable-line no-plusplus
    if (restoreFinished()) {
      window.zoomToDetail();
    } else {
      // Else recheck in 10 ms:
      setTimeout(() => {
        centerMap();
      }, 10);
    }
  }
};

// Resize the info tray to use 2/3 of the screen:
const resizeInfoTray = () => {
  const { infoTrayWrapper: wrapper } = window;
  const { infoTrayContent: content } = window;
  window.oldWrapperWidth = wrapper.style.width;
  window.oldContentWidth = content.style.width;
  wrapper.style.width = '66%';  // The tray should be 2/3 of the screen:
  content.style.width = '100%';  // The inner tray content should use the full width of the container.

  // Hide filter tray:
  const { filterTray } = window;
  filterTray.style.display = 'none';

  // Set body width and height ratio for printing:
  document.body.style.width = '1100px';
  document.body.style.height = '785px';
};

const CloseButton = options => {
  const { props, streetView, value } = options;
  return (
    <IconButton
      className={classNames(styles.topButton, {[styles.condensed]: !streetView})}
      style={getStyle(value, options)}
      onClick={props.closeDetails}
    >
      <Icon>close</Icon>
    </IconButton>
  );
};

const PrintButton = options => {
  const { props, streetView, value } = options;
  return (
    <IconButton
      className={classNames(styles.topButton, {[styles.condensed]: !streetView})}
      style={getStyle(value, options)}
      onClick={() => {
        // Save on the global window object references to the DOM HTML nodes
        // for the tray and map:
        saveNodes();

        // The info tray width during the print preview should be 2/3 of the screen:
        resizeInfoTray();

        // Make the map use only the space left by the tray (1/3):
        resizeMap();

        // Focus on the project to make it appear centered on the map:
        props.zoomToDetail();

        // Store on the window object to run it again when restoring the UI after printing:
        window.zoomToDetail = props.zoomToDetail;

        // Blur the background:
        const backdrop = document.getElementById('global-backdrop');
        backdrop.style.display = 'block';

        // Display the popup:
        const content = document.getElementById('global-popup-content');
        content.innerHTML = 'Generating print preview...';
        const popup = document.getElementById('global-popup');
        popup.style.display = 'flex';

        setTimeout(() => {
          // Hide the backdrop and popup:
          backdrop.style.display = 'none';
          popup.style.display = 'none';

          // Trigger the browser's print preview window:
          window.print();

          // Set centerMap retries.
          // This waits 10 seconds on the worst case, if it has to wait more than 200 ms
          // there's probably a problem, so it's set to 1000, (i.e. 1000 x 10 ms) as
          // and exhaust valve.
          window.centerMapRetry = 1000;

          // Restore tray's and map's width (this will trigger 'bounds_changed'):
          restoreInfoTraySize();
          restoreMap();

          // Center the map again:
          centerMap();
        }, 1000);
      }}
    >
      <Icon>print</Icon>
    </IconButton>
  );
};

const SubscribeButton = options => {
  const { props, value } = options;
  const { icon, label } = value;
  return (
    <Button
      className={classNames(styles.baseButton)}
      onClick={props.subscribe}
      style={getStyle(value, options)}
    >
      <Icon style={getStyle(icon, options)}>add_alert</Icon>
      <div style={getStyle(label, options)}>SUBSCRIBE</div>
    </Button>
  );
};

const ZoomButton = options => {
  const { props, value } = options;
  const { icon, label } = value;
  return (
    <Button
      className={classNames(styles.baseButton)}
      onClick={props.zoomToDetail}
      style={getStyle(value, options)}
    >
      <Icon style={getStyle(icon, options)}>zoom_in</Icon>
      <div style={getStyle(label, options)}>ZOOM TO</div>
    </Button>
  );
};

class TooltipButton extends Component {
  state = { anchorEl: null };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const { value } = this.props;
    const { body, icon, title } = value;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton
          className={classNames(styles.baseButton)}
          onClick={this.handleClick}
          style={getStyle(value, this.props)}
        >
          <Icon style={getStyle(icon, this.props)}>help</Icon>
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div className={classNames(styles.tooltipContainer)}>
            <div className={classNames(styles.tooltipHeader)}>
              <div className={classNames(styles.tooltipTitle)}>
                {title}
              </div>
              <IconButton
                className={classNames(styles.tooltipClose)}
                style={{
                  margin: 0,
                  padding: 0
                }}
                onClick={this.handleClose}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div className={classNames(styles.tooltipBody)}>
              {body}
            </div>
          </div>
        </Popover>
      </Fragment>
    );
  }
}

const StreetViewButton = options => {
  const { props, streetView, value } = options;
  if (streetView) {
    const { icon, label } = value;
    return (
      <Button
        className={classNames(styles.baseButton)}
        onClick={props.openStreetView}
        style={getStyle(value, options)}
      >
        <Icon style={getStyle(icon, options)}>streetview</Icon>
        <div style={getStyle(label, options)}>STREET VIEW</div>
      </Button>
    );
  }
  return null;
};

const SmallBackButton = options => {
  const { props, streetView } = options;
  if (props.mobileMaxDetails.visible) {
    return (
      <IconButton
        className={
          classNames(
            styles.mobileCollapseButton,
            {
              [styles.mobileCollapseButtonNonStreetView]: !streetView
            }
          )
        }
        onClick={props.closeMobileDetails}
      >
        <Icon>arrow_back</Icon>
      </IconButton>
    );
  }
  return null;
};

const StreetView = options => {
  const { data, props, streetView, value } = options;
  if (streetView) {
    const { lng, lat } = data.center || getBboxCenter(data.bbox).geometry;
    return (
      <div
        className={styles.streetView}
        key={uuid()}
        onClick={props.openStreetView}
        style={getStyle(value, options)}
      >
        <StaticStreetView
          className={styles.streetViewInner}
          lng={lng}
          lat={lat}
          apiKey={apiKey}
        />
        <div className={styles.streetViewOverlay} />
      </div>
    );
  }
  return <div className={styles.streetViewPlaceHolder} key={uuid()} />;
};

const Details = options => {
  const { data, props, streetView } = options;
  const { detailData, mapData } = props;
  const { layerData } = detailData;
  const { lng, lat } = data.center || getBboxCenter(data.bbox).geometry;
  const detailAttributes = data.attrs;
  const aliases = getAliases(layerData);
  const detailAliases = aliases.filter(([,, flags]) => !flags.has('footer'));
  const footerAliases = aliases.filter(([,, flags]) => flags.has('footer'));
  const category = getCategory(mapData, detailData);
  const valueMap = layerData.uiStyle.valueMap || null;

  const { remote_id } = data;
  const dispatch = useDispatch();
  const showMessage = message => dispatch(openSnackbar(message));

  return (
    <div key={uuid()} className={styles.detailsContainer}>
      <ThemeStyle style={SECONDARY_TEXT}>
        { (streetView || (layerData.uiStyle.directionsLink && data.center)) &&
        <div className={styles.navigationButtons}>
          {streetView &&
            <Button color="inherit" onClick={props.openStreetView}>
              <Icon>streetview</Icon><div className={styles.label}>STREETVIEW</div>
            </Button>
          }
          { remote_id && 
            <Button color="inherit" onClick={() => {
              const { detailData: { layerData: { name } } } = props;
              const url = `${location.origin}/map?link_type=${name}&link_value=${remote_id}`;
              // Copy the URL to the clipboard:
              navigator.clipboard.writeText(url);
              showMessage('Location link copied into clipboard.');
            }}>
              <Icon>share</Icon><div className={styles.label}>SHARE</div>
            </Button>
          }
          {layerData.uiStyle.directionsLink && data.center &&
            <a
              href={getDirectionsUrl(lat, lng)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="inherit">
                <Icon>directions</Icon>
                <div className={styles.label}>
                  GET DIRECTIONS
                </div>
              </Button>
            </a>
          }
        </div>
        }
      </ThemeStyle>
      <div className={styles.details} >
        {category &&
          <Fragment>
            <LargeMedia>
              <div className={classNames(styles.type, {[styles.condensed]: !streetView})}>{category} Information</div>
            </LargeMedia>
            <SmallMedia>
              <div className={styles.type}>{category} Information</div>
            </SmallMedia>
          </Fragment>
        }
        { detailAliases.map(([fields, label, flags], index) => {
          const value = resolveFields(fields, detailAttributes, valueMap);
          return (
            <DetailText key={index} className={styles.detail} label={label} value={value} flags={flags}/>
          );
        })}
      </div>
      <div className={styles.footer} >
        <div className={styles.divider} />
        { footerAliases.map(([fields, label, flags], index) => {
          const value = resolveFields(fields, detailAttributes, valueMap);
          return (
            <DetailText key={index} className={styles.detail} label={label} value={value} flags={flags}/>
          );
        })
        }
      </div>
    </div>
  );
};

// Create the US currency formatter.
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // Options to round whole numbers:
  minimumFractionDigits: 0, // will print 2500.10 as $2,500.1
  maximumFractionDigits: 0  // causes 2500.99 to be printed as $2,501
});

const renderField = (data, name, type, emptyMessage, other) => {
  if (data && typeof data.attrs[name] !== 'undefined' && data.attrs[name] !== null && data.attrs[name] !== '') {
    if (type === 'currency') {
      return currencyFormatter.format(data.attrs[name]).replace(/^(\D+)/, '$1 '); // Append a space after the dollar sign ('$ ').
    }
    if (type === 'hyperlink') {
      return (
        <a href={data.attrs[name]} target="_blank">
          {other?.linkText ? other?.linkText : data.attrs[name]}
        </a>
      );
    }
    if (type === 'percent') {
      return `${data.attrs[name]}%`;
    }
    if (type === 'colon-bullet') {
      // Format colon separated sentences as items in a bullet list:
      const items = data.attrs[name].split(':');
      return (
        <ul className={styles.bulletList}>
          {items.map(item => <li key={uuid()}>{item}</li>)}
        </ul>
      );
    }
    return data.attrs[name];
  }

  return emptyMessage || 'To Be Determined';
};

const Field = options => {
  const { data, value } = options;
  if (isString(value)) {
    return <div key={uuid()}>{renderField(data, value, null, null, null)}</div>;
  }
  const { name, type, emptyMessage, ...other } = value;
  return <div key={uuid()} style={getStyle(other, options)}>{renderField(data, name, type, emptyMessage, other)}</div>;
};

const Type = options => {
  const { props, value } = options;
  const { detailData, mapData } = props;
  const types = getTypes(mapData, detailData);
  return <div key={uuid()}>{types[value]}</div>;
};

const Logo = options => {
  const { value } = options;
  const { url } = value;
  return <img key={uuid()} src={url} style={getStyle(value, options)} alt="" />;
};

const Watermark = options => {
  const { value } = options;
  return <div className={styles.watermark} key={uuid()} style={getStyle(value, options)} />;
};

const TemplateIcon = options => {
  const { value } = options;
  const { name } = value;
  return <Icon style={getStyle(value, options)}>{name}</Icon>;
};

const progressStyles = () => ({
  root: {
    flexGrow: 1
  },
  linearColorPrimary: {
    backgroundColor: '#A9B3EB'
  },
  linearBarColorPrimary: {
    backgroundColor: '#5367D7'
  }
});

const ProgressBar = options => {
  const { classes, data, value } = options;
  const { percent } = value;
  return (
    <LinearProgress
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary
      }}
      style={getStyle(value, options)}
      variant="determinate"
      value={parseInt(data.attrs[percent], 10)}
    />
  );
};

const CustomizedProgressBar = withStyles(progressStyles)(ProgressBar);

// Template node renderer:
const render = (key, value, options) => {
  switch (key) {
  // Main 'div' tag:
  case 'main': return (
    <div key={uuid()} style={getStyle(value, options)} className={styles.infoDetailContainer}>
      {renderTemplate(value, options)}
    </div>
  );
  // Custom 'div' tag:
  case 'div': return (
    <div key={uuid()} style={getStyle(value, options)}>
      {renderTemplate(value, options)}
    </div>
  );
  // Large/Small media queries:
  case 'largeMedia': return (
    <LargeMedia>
      {renderTemplate(value, options)}
    </LargeMedia>
  );
  case 'smallMedia': return (
    <SmallMedia>
      {renderTemplate(value, options)}
    </SmallMedia>
  );
  // Components:
  case 'locationsLink': return <LocationsLink {...options} />;
  case 'backButton': return <BackButton value={value} {...options} />;
  case 'closeButton': return <CloseButton value={value} {...options} />;
  case 'shareButton': return <ConnectedShareButton value={value} {...options} />;
  case 'printButton': return <PrintButton value={value} {...options} />;
  case 'tooltipButton': return <TooltipButton value={value} {...options} />;
  case 'subscribeButton': return <SubscribeButton value={value} {...options} />;
  case 'zoomButton': return <ZoomButton value={value} {...options} />;
  case 'streetViewButton': return <StreetViewButton value={value} {...options} />;
  case 'smallBackButton': return <SmallBackButton {...options} />;
  case 'streetView': return <StreetView value={value} {...options} />;
  case 'detailHeader': {
    return <DetailHeader detailData={options.props.detailData} mapRef={options.props.mapRef} />;
  }
  case 'details': return <Details {...options} />;
  // Fields:
  case 'field': return <Field value={value} {...options} />;
  // Icons:
  case 'icon': return <TemplateIcon value={value} {...options} />;
  // Nested:
  case 'children': return renderTemplate(value, options);
  // Handle types:
  case 'type': return <Type value={value} {...options} />;
  // Logo:
  case 'logo': return <Logo value={value} {...options} />;
  // Watermark logo:
  case 'watermark': return <Watermark value={value} {...options} />;
  // Static progress bar:
  case 'progressBar': return <CustomizedProgressBar value={value} {...options} />;
  // Reusable template components:
  case 'component': {
    let component = null;
    if (isString(value)) {
      component = options.components[value];
    } else {
      const { name, style } = value;
      component = options.components[name];
      if (style) {
        // Merge component styles:
        component = {
          div: {
            ...component.div,
            style: {
              ...component.div.style,
              ...style
            }
          }
        };
      }
    }
    return renderTemplate(component, options);
  }
  default:
    return null;
  }
};

const parseClass = (classes, current) => {
  const { parent: parentClass, ...other } = current;
  // Inject all styles from the referenced parent class:
  if (parentClass) {
    const newCurrent = classes[parentClass];
    return {
      ...parseClass(classes, newCurrent),
      ...other
    };
  }
  return { ...other };
};

// Parse template class names to inject parent CSS clauses:
export const parseClassNames = classes => {
  const parsedStyles = {};
  if (classes) {
    Object.keys(classes).forEach(key => {
      const current = classes[key];
      parsedStyles[key] = { ...parseClass(classes, current) };
    });
  }
  return parsedStyles;
};
